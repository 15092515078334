import React, { memo, useState } from "react";

const Uploader = ({ upload }) => {
  const [dragging, setDragging] = useState(false);

  const preventDefault = (e) => e.preventDefault();
  const handleChange = (e) => upload(e.target.files);
  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    upload(e.dataTransfer.files);
  };
  const handleDragEnter = () => setDragging(true);
  const handleDragLeave = (e) => {
    e.stopPropagation();
    setDragging(false);
  };

  return (
    <div className="uploader">
      <div className="uploader__rules">
        <span>
          <div className="uploader__rules__prefix" />
          Один запрос не более
          <span className="font__blue">{`\u00A010 файлов`}</span>
        </span>
        <span>
          <div className="uploader__rules__prefix" />
          Максимальный суммарный размер файлов
          <span className="font__blue">{`\u00A010 Мб`}</span>
        </span>
        <span>
          <div className="uploader__rules__prefix" />
          Допустимые форматы
          <span className="font__blue">{`\u00A0XML и TXT`}</span>
        </span>
      </div>
      <label
        htmlFor="upload-btn"
        className={`uploader__button ${
          dragging ? " uploader__button_dragging" : ""
        }`}
      >
        Выбрать файлы
      </label>
      <input
        type="file"
        id="upload-btn"
        accept=".xml, .txt"
        multiple
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <div
        className={`uploader__dnd ${dragging ? " uploader__dnd_dragging" : ""}`}
        onDrop={handleDrop}
        onDragOver={preventDefault}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      />
    </div>
  );
};

export default memo(Uploader);
